
@media only screen and (max-width: 1199px) {

	.header-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 0 auto;
	}

	.secondary-title h2 {
		font-size: 70px;
		line-height: 1.2em;
	}
	
}

@media only screen and (max-width: 1024px) {
	
	.image-section-1 {
			background-attachment: scroll;
	}

	.image-section-2 {
			background-attachment: scroll;
	}
}

@media only screen and (max-width: 997px) {

	.main-nav a {
		display: flex;
		justify-content: center;
		border-bottom: 2px solid #4d727e;
		padding-top: 10px;
		margin: 0;
	}

	.main-nav a:nth-child(6) {
		border-bottom: none;
	}

	.active {
		border: none;
	}

	.menu-trigger {
		display: block;
		margin-top: 20px;
	}

	.main-nav {
		display: none;
	}

	.nav-expanded {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.social {
		display: none;
	}

	.main-title {
		font-size: 90px;
	}

	.sub-title-top, .sub-title-bottom {
		font-size: 26px;
	}

	.btn {
		margin-bottom: 40px;
	}

	.story-analysis-info {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.secondary-title, .description {
		width: 100%;
	}

	.bio {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.secondary-title h2 br {
		display: none;
	}

	.story-analysis-features {
		margin: 150px 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 75px 0;
	}

	.feature-container {
		width: 100%;
		margin-bottom: 40px;
	}

	#testimonials {
		padding-top: 75px;
	}

	#contact {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
}

@media only screen and (max-width: 768px) {
	

	#landing {
		height: 100%;
	}

	.award-container {
		width: 50%;
		height: auto;
	}	
}
	
@media only screen and (max-width: 480px) {

		.main-title {
			font-size: 70px;
		}

		.sub-title-top, .sub-title-bottom {
			font-size: 20px;
		}

		#services {
			padding-top: 50px;
		}

		.secondary-title h2 {
			font-size: 60px;
			line-height: 1.2em;
			font-weight: 300;
		}
		
		#about {
			padding-top: 0;
		}

		.awards {
			display: none;
		}


		.main-carousel:after {
			content: '';
		}

		.carousel-cell {
			width: 100%;
			padding: 20px;
			margin: 20px 0;
			background-color: #4d727e;
			color: #ffffff;
			border-radius: 5px;
		}

		.quote-small {
			margin-top: 20px;
		}

		#contact {
			padding-bottom: 100px;
		}

		.image-section-1 {
			display: none;
		}

		.image-section-2 {
			display: none;
		}
}

@media only screen and (max-width: 320px) {
	
	.description a {
		font-size: 20px;
	}

	#footer {
		height: 200px;
	}

	.developer span {
		display: block;
	}


}







